import { BehaviorSubject, fromEvent, merge, Subject } from "rxjs";
import {
  distinctUntilChanged,
  map,
  startWith,
  withLatestFrom
} from "rxjs/operators";
import { dragLeave, dragOver } from "../helpers/dom";
import { imageElementsFromInputEvent } from "../helpers/input";

const isDragging = (
  document: Document,
  inputElement: HTMLInputElement
): BehaviorSubject<boolean> => {
  const isDragging$ = new BehaviorSubject(false);
  let timeout: number;

  const dragOver$ = dragOver(document).pipe(map(() => true));
  const dragLeave$ = dragLeave(document).pipe(map(() => false));
  const inputChange$ = fromEvent(inputElement, "change").pipe(map(() => false));

  merge(dragOver$, dragLeave$, inputChange$)
    .pipe(
      startWith(false),
      withLatestFrom(isDragging$),
      distinctUntilChanged((prev, next) => prev[0] === next[0])
    )
    .subscribe(([next, current]) => {
      clearTimeout(timeout);

      if (next) {
        isDragging$.next(true);
      } else if (!next && current) {
        timeout = setTimeout(() => {
          isDragging$.next(false);
        }, 50);
      } else {
        isDragging$.next(false);
      }
    });

  return isDragging$;
};

export class Dropzone {
  public inputElement: HTMLInputElement;
  public isDragging$: BehaviorSubject<boolean>;
  private document: Document;
  private image$: Subject<HTMLImageElement>;

  constructor(document: Document, image$: Subject<HTMLImageElement>) {
    this.document = document;
    this.image$ = image$;

    this.configureNodes();
    this.configureStreams();
  }

  private configureNodes() {
    // Configure input
    this.inputElement = this.document.createElement("input");
    this.inputElement.setAttribute("type", "file");
    this.inputElement.setAttribute("accept", "images/*");
    this.inputElement.setAttribute("multiple", "multiple");

    // Setup styling for input
    const style: { [key: string]: string } = {
      position: "fixed",
      opacity: "0",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%"
    };
    Object.keys(style).forEach(key => {
      this.inputElement.style[key] = style[key];
    });
  }

  private configureStreams() {
    // Dragging and showing/hiding of dropzone input
    this.isDragging$ = isDragging(this.document, this.inputElement);
    this.isDragging$.subscribe(shouldShowInput => {
      if (shouldShowInput) {
        this.showInput();
      } else {
        this.hideInput();
      }
    });

    // Handling of drops
    fromEvent(this.inputElement, "change").subscribe(event => {
      imageElementsFromInputEvent(event).subscribe(image =>
        this.image$.next(image)
      );

      // We need to clear to input to make sure a change event is triggered when uploading the same file again
      this.inputElement.value = "";
    });
  }

  private hideInput() {
    this.inputElement.style.visibility = "hidden";
  }

  private showInput() {
    this.inputElement.style.visibility = "visible";
  }
}
