import { Observable, Subject } from "rxjs";
import { filter, map, takeUntil, withLatestFrom } from "rxjs/operators";
import { getContext } from "../helpers/canvas";
import { keyDown } from "../helpers/keyboard";
import { mouseDown, mouseMove, mouseUp } from "../helpers/mouseEvents";
import { Screenshot } from "./Screenshot";
import Vector from "./Vector";

export interface Drawable {
  position: Vector;
  width: number;
  height: number;
  draw: (context: CanvasRenderingContext2D) => void;
  contains: (vector: Vector) => boolean;
  selectable: boolean;
}

const toVector = (event: MouseEvent) =>
  new Vector(event.offsetX, event.offsetY);

const bodyHasFocus = (document: Document) => () =>
  document.activeElement === document.body;

const keyCodeToNudgeVector = (keyCode: number, nudgeAmount: number) => {
  switch (keyCode) {
    case LEFT_KEY:
      return new Vector(-nudgeAmount, 0);
    case RIGHT_KEY:
      return new Vector(nudgeAmount, 0);
    case UP_KEY:
      return new Vector(0, -nudgeAmount);
    case DOWN_KEY:
      return new Vector(0, nudgeAmount);
    default:
      return new Vector(0, 0);
  }
};

// Typed version of https://stackoverflow.com/a/39271175
const moveElementFromIndexToIndex = <T>(
  array: T[],
  fromIndex: number,
  toIndex: number
): T[] => {
  return array.reduce((prev, current, idx, self) => {
    if (fromIndex === toIndex) {
      prev.push(current);
    }
    if (idx === fromIndex) {
      return prev;
    }
    if (fromIndex < toIndex) {
      prev.push(current);
    }
    if (idx === toIndex) {
      prev.push(self[fromIndex]);
    }
    if (fromIndex > toIndex) {
      prev.push(current);
    }
    return prev;
  }, []);
};

const OPACITY_DECREASE_KEY = 189;
const OPACITY_INCREASE_KEY = 187;
const BACKSPACE_KEY = 8;
const LEFT_KEY = 37;
const UP_KEY = 38;
const RIGHT_KEY = 39;
const DOWN_KEY = 40;
const ORDER_DOWN_KEY = 219;
const ORDER_UP_KEY = 221;

export default class Workspace {
  private document: Document;
  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;
  private render$: Observable<any>;
  private image$: Observable<HTMLImageElement>;
  private selection$: Subject<Screenshot>;
  private drawables: Drawable[] = [];

  constructor(
    document: Document,
    canvas: HTMLCanvasElement,
    render$: Observable<any>,
    image$: Observable<HTMLImageElement>,
    selection$: Subject<Screenshot>
  ) {
    this.document = document;
    this.canvas = canvas;
    this.context = getContext(this.canvas);
    this.render$ = render$;
    this.image$ = image$;
    this.selection$ = selection$;

    this.configureStreams();
  }

  private configureStreams() {
    this.configureRenderStream();
    this.configureImageStream();
    this.configureDraggingStream();
    this.configureSelectionStream();
    this.configureOpacityStream();
    this.configureDeletionStream();
    this.configureNudgingStream();
    this.configureReorderStream();
  }

  private configureRenderStream() {
    this.render$
      .pipe(withLatestFrom(this.selection$))
      .subscribe(([_, selectedScreenshot]) => {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

        this.drawables.forEach(drawable => {
          drawable.draw(this.context);

          if (drawable === selectedScreenshot) {
            this.highlight(
              drawable.position.x,
              drawable.position.y,
              drawable.width,
              drawable.height
            );
          }
        });
      });
  }

  private configureImageStream() {
    this.image$.subscribe(image => {
      const screenshot = new Screenshot(image);
      this.drawables.push(screenshot);
    });
  }

  private configureDraggingStream() {
    const mouseDown$ = mouseDown(this.canvas);
    mouseDown$.pipe(map(toVector)).subscribe(vector => {
      const target = this.findTarget(vector);

      if (target) {
        this.handleDrag(target, vector);
      }
    });
  }

  private configureSelectionStream() {
    const mouseDown$ = mouseDown(this.canvas);
    mouseDown$
      .pipe(
        filter(event => event.target === this.canvas),
        map(toVector)
      )
      .subscribe(clickPosition => {
        const target = this.findTarget(clickPosition) as Screenshot;
        this.selection$.next(target);
      });
  }

  private configureOpacityStream() {
    const keyDown$ = keyDown(this.document);
    keyDown$
      .pipe(
        withLatestFrom(this.selection$),
        filter(([_, selectedScreenshot]) => selectedScreenshot !== undefined),
        map(([keyEvent, selectedScreenshot]) => ({
          keyCode: keyEvent.keyCode,
          selectedScreenshot
        }))
      )
      .subscribe(({ keyCode, selectedScreenshot }) => {
        if (keyCode === OPACITY_DECREASE_KEY) {
          selectedScreenshot.decreaseOpacity();
        }

        if (keyCode === OPACITY_INCREASE_KEY) {
          selectedScreenshot.increaseOpacity();
        }
      });
  }

  private configureDeletionStream() {
    const keyDown$ = keyDown(this.document);
    keyDown$
      .pipe(
        filter(bodyHasFocus(this.document)),
        withLatestFrom(this.selection$),
        filter(
          ([keyEvent, selectedScreenshot]) =>
            keyEvent.keyCode === BACKSPACE_KEY &&
            selectedScreenshot !== undefined
        )
      )
      .subscribe(([_, selectedScreenshot]) => {
        // Deselect screenshot
        this.selection$.next(undefined);

        // Remove screenshot
        this.drawables = this.drawables.filter(
          drawable => drawable !== selectedScreenshot
        );
      });
  }

  private configureNudgingStream() {
    const keyDown$ = keyDown(this.document);
    keyDown$
      .pipe(
        filter(bodyHasFocus(this.document)),
        withLatestFrom(this.selection$),
        filter(
          ([event, selectedScreenshot]) =>
            [UP_KEY, RIGHT_KEY, DOWN_KEY, LEFT_KEY].includes(event.keyCode) &&
            !!selectedScreenshot
        )
      )
      .subscribe(([event, selectedScreenshot]) => {
        event.preventDefault();

        const keyCode = event.keyCode;
        const nudgeAmount = event.shiftKey ? 10 : 1;
        const nudge = keyCodeToNudgeVector(keyCode, nudgeAmount);

        selectedScreenshot.position.add(nudge);
      });
  }

  private configureReorderStream() {
    const keyDown$ = keyDown(this.document);
    keyDown$
      .pipe(
        filter(() => this.drawables.length >= 2), // Only do all this if you can even change the order
        filter(bodyHasFocus(this.document)),
        withLatestFrom(this.selection$),
        filter(
          ([event, selectedScreenshot]) =>
            [ORDER_DOWN_KEY, ORDER_UP_KEY].includes(event.keyCode) &&
            !!selectedScreenshot
        )
      )
      .subscribe(([event, selectedScreenshot]) => {
        const currentIndex = this.drawables.indexOf(selectedScreenshot);
        const canGoUp = currentIndex < this.drawables.length - 1;
        const canGoDown = currentIndex >= 1;

        if (event.keyCode === ORDER_DOWN_KEY && canGoDown) {
          this.drawables = moveElementFromIndexToIndex(
            this.drawables,
            currentIndex,
            currentIndex - 1
          );
        } else if (event.keyCode === ORDER_UP_KEY && canGoUp) {
          this.drawables = moveElementFromIndexToIndex(
            this.drawables,
            currentIndex,
            currentIndex + 1
          );
        }
      });
  }

  private findTarget(vector: Vector): Drawable | undefined {
    const drawablesAtVector = this.drawables.filter(
      drawable => drawable.selectable && drawable.contains(vector)
    );

    // For now take the last in the list (since that's probably the top one)
    return drawablesAtVector[drawablesAtVector.length - 1];
  }

  private handleDrag(target: Drawable, mouseDownPosition: Vector) {
    const targetStartPosition = new Vector(
      target.position.x,
      target.position.y
    );

    const mouseMove$ = mouseMove(this.canvas);
    const mouseUp$ = mouseUp(this.document);

    mouseMove$
      .pipe(
        takeUntil(mouseUp$),
        map(toVector),
        map(
          currentMousePosition =>
            new Vector(
              currentMousePosition.x - mouseDownPosition.x,
              currentMousePosition.y - mouseDownPosition.y
            )
        )
      )
      .subscribe(mouseDelta => {
        target.position = new Vector(
          targetStartPosition.x + mouseDelta.x,
          targetStartPosition.y + mouseDelta.y
        );
      });
  }

  private highlight(x: number, y: number, width: number, height: number) {
    const lineWidth = 2;
    const halfLineWidth = lineWidth / 2;
    this.context.beginPath();
    this.context.lineWidth = lineWidth;
    this.context.strokeStyle = "#19A0FB";
    this.context.rect(
      x - halfLineWidth,
      y - halfLineWidth,
      width + lineWidth,
      height + lineWidth
    );
    this.context.stroke();
  }
}
