import { fromEvent } from "rxjs";
import { FromEventTarget } from "rxjs/internal/observable/fromEvent";

export const mouseDown = (element: FromEventTarget<MouseEvent>) =>
  fromEvent<MouseEvent>(element, "mousedown");

export const mouseUp = (element: FromEventTarget<MouseEvent>) =>
  fromEvent<MouseEvent>(element, "mouseup");

export const mouseMove = (element: FromEventTarget<MouseEvent>) =>
  fromEvent<MouseEvent>(element, "mousemove");

export const mouseClick = (element: FromEventTarget<MouseEvent>) =>
  fromEvent<MouseEvent>(element, "click");
