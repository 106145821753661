import { Observable } from "rxjs";

export const loadImage = (src: string) =>
  new Observable<HTMLImageElement>(subscriber => {
    const image = new Image();
    image.onload = () => {
      subscriber.next(image);
      subscriber.complete();
    };

    image.onerror = error => {
      subscriber.error(error);
      subscriber.complete();
    };

    image.crossOrigin = "Anonymous";
    image.src = src;
  });
