export function resizeCanvas(
  width: number,
  height: number,
  pixelRatio: number,
  canvas: HTMLCanvasElement
) {
  // Set canvas size
  canvas.width = width * pixelRatio;
  canvas.height = height * pixelRatio;

  // Set element size
  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;

  // Set scaling
  const ctx = getContext(canvas);
  ctx.scale(pixelRatio, pixelRatio);
}

export function getContext(
  canvas: HTMLCanvasElement
): CanvasRenderingContext2D {
  return canvas.getContext("2d")!;
}
