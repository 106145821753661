import { from, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { loadImage } from "./image";

export const imageElementsFromInputEvent = (
  inputEvent: Event
): Observable<HTMLImageElement> => {
  const inputElement = inputEvent.target as HTMLInputElement;
  const fileUrls = Array.from(inputElement.files).map(file =>
    URL.createObjectURL(file)
  );

  return from(fileUrls).pipe(mergeMap(loadImage));
};
