import { BehaviorSubject, Subject } from "rxjs";
import { startWith } from "rxjs/operators";
import { Dropzone } from "./classes/Dropzone";
import Panel from "./classes/Panel";
import { Screenshot } from "./classes/Screenshot";
import Workspace from "./classes/Workspace";
import { resizeCanvas } from "./helpers/canvas";
import { animationFrame$, documentReady } from "./helpers/dom";
import { resize } from "./helpers/window";

const documentReady$ = documentReady(window.document);
documentReady$.subscribe(() => {
  const view = document.body;

  const canvas = document.createElement("canvas");
  view.appendChild(canvas);

  const windowResize$ = resize(window);
  windowResize$.pipe(startWith(undefined)).subscribe(() => {
    resizeCanvas(
      window.innerWidth,
      window.innerHeight,
      window.devicePixelRatio,
      canvas
    );
  });

  const image$ = new Subject<HTMLImageElement>();
  const selection$ = new BehaviorSubject<Screenshot>(undefined);

  const workspace = new Workspace(
    document,
    canvas,
    animationFrame$,
    image$,
    selection$
  );
  const panel = new Panel(document, image$, selection$);
  const dropzone = new Dropzone(document, image$);

  view.append(panel.node);
  view.append(dropzone.inputElement);
});
