import { fromEvent, Subject } from "rxjs";
import { filter, map, withLatestFrom } from "rxjs/operators";
import { imageElementsFromInputEvent } from "../helpers/input";
import { Screenshot } from "./Screenshot";

export default class Panel {
  public node: HTMLDivElement;
  private document: Document;
  private image$: Subject<HTMLImageElement>;
  private selection$: Subject<Screenshot>;
  private uploadButton: HTMLInputElement;
  private pixelsPerPointInput: HTMLInputElement;

  constructor(
    document: Document,
    image$: Subject<HTMLImageElement>,
    selection$: Subject<Screenshot>
  ) {
    this.document = document;
    this.image$ = image$;
    this.selection$ = selection$;
    this.configureNodes();
    this.configureStreams();
  }

  private configureNodes() {
    // Main node
    this.node = this.document.createElement("div");
    this.node.classList.add("panel");

    // Upload button
    this.uploadButton = this.document.createElement("input");
    this.node.appendChild(this.uploadButton);
    this.uploadButton.classList.add("panel__upload-button");
    this.uploadButton.setAttribute("type", "file");
    this.uploadButton.setAttribute("accept", "images/*");
    this.uploadButton.setAttribute("multiple", "multiple");

    // Pixels per point label
    const label = this.document.createElement("div");
    this.node.appendChild(label);
    label.classList.add("panel__label");
    label.innerText = "Pixels per point";

    // Pixels per point input
    this.pixelsPerPointInput = this.document.createElement("input");
    this.node.appendChild(this.pixelsPerPointInput);
    this.pixelsPerPointInput.setAttribute("type", "number");
  }

  private configureStreams() {
    // Upload button
    fromEvent(this.uploadButton, "change").subscribe(event => {
      imageElementsFromInputEvent(event).subscribe(image =>
        this.image$.next(image)
      );

      // We need to clear to input to make sure a change event is triggered when uploading the same file again
      this.uploadButton.value = "";
    });

    // Selected image
    this.selection$.subscribe(screenshot => {
      if (!screenshot) {
        this.screenshotDeselected();
      } else {
        this.screenshotSelected(screenshot);
      }
    });

    // Pixels per point changes
    const pixelsPerPointInput$ = fromEvent(
      this.pixelsPerPointInput,
      "change"
    ).pipe(map(event => (event.target as HTMLInputElement).valueAsNumber));

    pixelsPerPointInput$
      .pipe(
        withLatestFrom(this.selection$),
        filter(([_, selectedScreenshot]) => Boolean(selectedScreenshot))
      )
      .subscribe(([pixelsPerPoint, selectedScreenshot]) => {
        selectedScreenshot.pixelsPerPoint = pixelsPerPoint;
      });
  }

  private screenshotDeselected() {
    this.pixelsPerPointInput.disabled = true;
    this.pixelsPerPointInput.style.opacity = "0.5";
    this.pixelsPerPointInput.value = "0";
  }

  private screenshotSelected(screenshot: Screenshot) {
    this.pixelsPerPointInput.disabled = false;
    this.pixelsPerPointInput.style.opacity = "1";
    this.pixelsPerPointInput.value = `${screenshot.pixelsPerPoint}`;
  }
}
