import Vector from "./Vector";
import { Drawable } from "./Workspace";

export class Screenshot implements Drawable {
  public position = new Vector();
  public selectable = true;
  public pixelsPerPoint: number;
  public opacity: number = 1.0;
  private image: HTMLImageElement;

  constructor(image: HTMLImageElement, pixelsPerPoint: number = 1) {
    this.image = image;
    this.pixelsPerPoint = pixelsPerPoint;
  }

  get width(): number {
    return this.image.width * (1 / this.pixelsPerPoint);
  }

  get height(): number {
    return this.image.height * (1 / this.pixelsPerPoint);
  }

  public decreaseOpacity() {
    this.opacity = Math.max(0, this.opacity - 0.1);
  }

  public increaseOpacity() {
    this.opacity = Math.min(1, this.opacity + 0.1);
  }

  public draw(context: CanvasRenderingContext2D) {
    context.globalAlpha = this.opacity;
    context.drawImage(
      this.image,
      this.position.x,
      this.position.y,
      this.width,
      this.height
    );
    context.globalAlpha = 1;
  }

  public contains(vector: Vector): boolean {
    const containsX =
      this.position.x <= vector.x && this.position.x + this.width >= vector.x;

    const containsY =
      this.position.y <= vector.y && this.position.y + this.height >= vector.y;

    return containsX && containsY;
  }
}
