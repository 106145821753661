import { animationFrameScheduler, Observable, of, fromEvent } from "rxjs";
import { repeat } from "rxjs/operators";

/**
 * Returns a stream that emits an empty value when the passed document is ready
 * @param document Document that you want to be ready
 */
export const documentReady = (document: Document) =>
  new Observable<undefined>(subscriber => {
    // Maybe the document is already ready
    if (document.readyState === "complete") {
      subscriber.next();
      subscriber.complete();
    }

    // Listen for DOMContentLoaded event
    document.addEventListener("DOMContentLoaded", () => {
      subscriber.next();
      subscriber.complete();
    });
  });

export const animationFrame$ = of(null, animationFrameScheduler).pipe(repeat());

export const dragOver = (document: Document) => fromEvent(document, "dragover");

export const dragLeave = (document: Document) =>
  fromEvent(document, "dragleave");
